import React from 'react';
import { graphql } from "gatsby";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import WhiteButton from '../components/WhiteButton';

const NotFoundPage = ({ data }) => {
  const background = getImage(data.file.childImageSharp.gatsbyImageData);


  return (
    <main className="flex items-center justify-center bg-darks3">
      <GatsbyImage image={background} alt={'404 Background image'} className="opacity-40 min-h-[600px] md:min-h-[80vh] py-20" />
      <div className="absolute uppercase w-11/12 text-white mx-auto lg:flex lg:items-end py-28 lg:w-auto lg:px-10">
        <div className="">
          <h1 className="font-display text-5xl md:text-7xl lg:text-8xl">The <span className="text-blue">prospector</span> isn't dead,</h1>
          <h1 className="font-display text-5xl md:text-7xl lg:text-8xl">but this page <span className="text-blue">is dead.</span></h1>
          <h1 className="font-display text-5xl md:text-7xl lg:text-8xl"><span className="text-blue">404.</span> Page not found.</h1>
        </div>
        <div className="w-44 mt-12 lg:mt-0">
          <WhiteButton type="link" text="Back Home" link={`/`} />
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage

export const query = graphql`
 query ErrorPageQuery {
  file(relativePath: {eq: "images/static/droid_404.jpeg"}) {
    childImageSharp {
      gatsbyImageData
    }
  }
 }
`
import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const WhiteButton = ({ text, role, type, link, click }) => {
  let renderButton;

  switch (type) {
    case "link":
      renderButton = (
        <Link
          className=" relative flex flex-col items-center justify-center cursor-pointer w-full h-[65px]"
          to={link}
        >
          <span className="absolute text-lg md:text-xl font-body uppercase text-darks4 tracking-wider z-10 px-4 lg:px-6">
            {text}
          </span>
          <div className="absolute overflow-hidden clipped-input">
            <StaticImage
              src="../../assets/svgs/secondary_btn-01.svg"
              objectFit="fill"
              className="h-[65px] w-full z-0 px-2"
              alt=""
            />
          </div>
        </Link>
      );
      break;
    default:
      renderButton = (
        <button
          role={role}
          className={`relative flex flex-col items-center justify-center cursor-pointer h-[65px] clipped-input w-full`}
          onClick={click}
        >
          <span className="text-lg font-body md:text-xl uppercase text-darks4 tracking-wider z-0 px-4 lg:px-6">
            {text}
          </span>
          <div className="absolute overflow-hidden">
            <StaticImage
              src="../../assets/svgs/secondary_btn-01.svg"
              objectFit="fill"
              className="h-[65px] w-full z-[-10] px-2"
              alt=""
            />
          </div>
        </button>
      );
      break;
  }

  return <React.Fragment>{renderButton}</React.Fragment>;
};

export default WhiteButton;
